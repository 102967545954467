import { template as template_d58c31c4130f495682842b31f6268e32 } from "@ember/template-compiler";
const FKLabel = template_d58c31c4130f495682842b31f6268e32(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
