import { template as template_12cd1242b1bc4fb4aa9983b5b1c77208 } from "@ember/template-compiler";
const FKControlMenuContainer = template_12cd1242b1bc4fb4aa9983b5b1c77208(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
